<template>
	<PortalTarget
		ref="portal"
		:class="[
			'spa-overlay z-11000',
			{
				'spa-overlay--deactivated': deactivate,
				'spa-overlay--active': active,
			},
			name === 'mobileMenu' ? 'spa-overlay--menu' : '',
		]"
		:name="name"
		multiple
		:aria-hidden="active ? null : 'true'"
		@change="updateOverlays"
	/>
</template>

<script>
export default {
	name: 'SpaOverlay',
	props: {
		name: {
			type: String,
			default: 'SpaOverlay',
		},
		deactivate: {
			type: Boolean,
			default: false,
		},
		pageWrapperElement: {
			// type: intentionally removed to allow HTMLElements,
			default: 'body',
			validator() {
				return true;
			},
		},
	},
	data() {
		return {
			overlays: [],
		};
	},
	computed: {
		active() {
			return this.deactivate ? false : !!this.overlays?.length;
		},
		pageWrapperEl() {
			if (typeof window === 'undefined') {
				return null;
			}
			if (typeof this.pageWrapperElement === 'string') {
				return document.querySelector(this.pageWrapperElement);
			}
			if (this.pageWrapperElement instanceof Element) {
				return this.pageWrapperElement;
			}
			return null;
		},
	},
	watch: {
		active: {
			handler(active) {
				/**
				 * When active (ie., when it is not activated, and there is
				 * at least one non-empty "passenger"), we lock the page.
				 */
				if (this.pageWrapperEl) {
					const { scrollLeft, scrollTop } = this.pageWrapperEl;
					const wrapperRect =
						this.pageWrapperEl.getBoundingClientRect();
					if (active) {
						this.pageWrapperEl.classList.add(
							'spa-overlay__page-wrapper--active'
						);
						// Make content be placed correctly
						this.pageWrapperEl.scrollTop = -wrapperRect.top;
						this.pageWrapperEl.scrollLeft = -wrapperRect.left;
					} else {
						this.pageWrapperEl.classList.remove(
							'spa-overlay__page-wrapper--active'
						);
						// Get back where we started
						window.scrollBy(scrollLeft, scrollTop);
					}
				}
				this.$emit('update', active);
			},
			immediate: true,
		},
		pageWrapperEl: {
			handler(el, oldEl) {
				if (oldEl) {
					oldEl.classList.remove('spa-overlay__page-wrapper');
					oldEl.classList.remove('spa-overlay__page-wrapper--active');
				}
				if (el) {
					el.classList.add('spa-overlay__page-wrapper');
					if (this.active) {
						el.classList.add('spa-overlay__page-wrapper--active');
					}
				}
			},
			immediate: true,
		},
	},
	methods: {
		updateOverlays() {
			/**
			 * We filter away empty "passengers" so that,
			 * for exampel, transition tags may exist in
			 * the Portal-elements without activating the
			 * PortalTarget used.
			 */
			this.$nextTick(() => {
				this.overlays = this.$refs.portal?.passengers?.filter(
					(passenger) => {
						return (
							passenger.elm &&
							passenger.elm.nodeType !== 8 &&
							(passenger.elm.clientHeight ||
								passenger.elm.clientWidth)
						);
					}
				);
			});
		},
	},
};
</script>

<style>
.spa-overlay__page-wrapper--active {
	max-width: 100vw !important;
	max-height: 100vh !important;
	overflow: hidden !important;
}

.spa-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	pointer-events: none;
}

.spa-overlay--menu {
	top: 80px;
	height: calc(100% - 80px);
	overflow: auto;
}

.spa-overlay:empty {
	display: none;
}

.spa-overlay > * {
	overflow: auto;
	pointer-events: all;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch;
}
</style>
