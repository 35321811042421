<template>
	<div
		class="c-primary-navigation flex"
		role="navigation"
		aria-label="primær navigation"
	>
		<button
			class="
				>=1024:hidden
				bg-white
				rounded-full
				h-48
				w-48
				flex
				justify-center
				items-center
			"
			@click="onShow()"
		>
			<Burger />
		</button>
		<a
			href="/"
			aria-label="Til forsiden"
			class="
				>=1024:hidden
				bg-white
				rounded-full
				h-48
				w-48
				flex
				justify-center
				items-center
				ml-10
			"
			title="Gå til forsiden"
		>
			<SvgHome class="h-20 w-20 text-blue" />
		</a>
		<nav
			class="
				hidden
				>=1024:flex
				bg-white
				rounded-full
				h-48
				items-start
				px-25
				py-13
				space-x-30
			"
		>
			<NuxtLinkExtVue
				v-for="item in items"
				:key="item.id"
				:to="item.url"
				class="uppercase font-Rubik font-semibold"
				v-html="item.title"
			></NuxtLinkExtVue>
		</nav>
		<Portal v-if="isOpen" to="SpaOverlay">
			<div
				class="
					fixed
					top-0
					right-0
					w-full
					h-full
					bg-white
					text-blue
					z-30
					pt-100
					>=768:py-260
					px-100
				"
			>
				<button
					class="
						absolute
						top-30
						right-20
						>=768:top-33 >=768:right-33
						rounded-full
						bg-blue
						w-48
						h-48
						flex
						justify-center
						items-center
						text-white
						transform
						hover:rotate-180
						transition
						duration-500
						ease-in-out
					"
					@click="onClose()"
				>
					<SvgClose class="w-12 h-12" />
				</button>
				<nav class=">=1024:hidden">
					<a href="/" class="block text-center w-full mb-20"
						><SvgLogo class="text-action w-100 mx-auto"
					/></a>
					<NuxtLinkExtVue
						v-for="item in items"
						:key="item.id"
						:to="item.url"
						class="block uppercase text-48 text-center font-bold"
						@click.native="onClose()"
						v-html="item.title"
					></NuxtLinkExtVue>
				</nav>
			</div>
		</Portal>
	</div>
</template>

<script>
import Burger from '~/components/main/Burger';
import NuxtLinkExtVue from '~/citi-baseline/components/NuxtLinkExt.vue';
import SvgClose from '~/assets/svgs/close.svg?inline';
import SvgLogo from '~/assets/svgs/logo.svg?inline';
import SvgHome from '~/assets/svgs/home.svg?inline';

export default {
	name: 'PrimaryNavigation',

	components: {
		Burger,
		NuxtLinkExtVue,
		SvgClose,
		SvgLogo,
		SvgHome,
	},

	props: {
		items: {
			type: Array,
			default: () => [],
		},
		sticky: {
			type: Boolean,
			default: false,
		},
		contactpersons: Array,
	},

	data() {
		const { page } = this.$store.state.current;

		return {
			...page,
			isOpen: false,
			index: 0,
		};
	},

	created() {
		this.$on('overlay.close', this.onClose);
	},

	methods: {
		onClose() {
			this.isOpen = false;
		},
		onShow() {
			this.isOpen = true;
		},
	},
};
</script>
<style>
.c-primary-navigation .nuxt-link--active:after {
	@apply bg-blue;
	@apply mx-auto;
	@apply rounded-full;
	content: '';
	display: block;
	height: 3px;
	width: 15px;
}
</style>
