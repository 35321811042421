<template>
	<div class="c-block-list-block-text-custom">
		<span v-if="byline" v-html="byline"></span>
		<Component
			v-if="title && fromoverlay"
			is="h3"
			v-html="title"
			class="blockheader"
		></Component>
		<Component
			v-else
			is="h2"
			v-html="title"
			class="blockheader"
		></Component>

		<div v-if="text" class="rte" v-html="text"></div>
	</div>
</template>

<script>
export default {
	name: 'BlockListTextCustom',

	inheritAttrs: true,

	props: {
		byline: String,
		title: String,
		text: String,
		fromoverlay: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style>
.c-block-list-block-text-custom .rte {
	@apply text-18;
	@apply mt-40;

	@screen >=768 {
		@apply text-24;
		@apply mt-60;
	}
}

.vue-portal-target .c-block-list-block-text-custom .rte {
	@apply text-16;
	@apply mt-40;

	@screen >=768 {
		@apply text-24;
		@apply mt-60;
	}
}

.vue-portal-target .c-block-list-block-text-custom .rte h2 {
	@apply uppercase;
	@apply text-20;
	@apply text-blue;
	@apply mb-10;
	@apply mt-30;

	font-weight: 700;

	@screen >=768 {
		@apply text-32;
	}
}

.vue-portal-target .c-block-list-block-text-custom .rte a {
	@apply underline;
}
</style>
