<template>
	<div v-if="blocks && blocks.length" class="c-block-list">
		<template v-for="block in blocks">
			<Component
				:is="getBlockNameFromAlias(block.alias)"
				v-if="blockExists(block.alias)"
				:key="block.key"
				:fromoverlay="fromoverlay"
				v-bind="block.content"
			/>

			<BlockListPlaceholder
				v-else-if="isDevelopment"
				:key="block.key"
				v-bind="block"
			/>
		</template>
	</div>
</template>

<script>
import BlockListPlaceholder from './blocks/BlockListPlaceholder.vue';

import {
	getBlockNameFromAlias,
	getBlocksBySolution,
} from '~/assets/js/block-lists';

const blocks = getBlocksBySolution('main');
const blockNames = Object.keys(blocks);

export default {
	name: 'MainBlockList',

	components: {
		BlockListPlaceholder,

		...blocks,
	},

	props: {
		blockList: {
			type: Array,

			default: () => [],
		},
		fromoverlay: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
			blockNames,
		};
	},

	computed: {
		blocks() {
			return (this.blockList ?? []).filter((block) => {
				if (!block || block?.content?.deactivated === true) {
					return false;
				}

				if (block?.alias && block?.content && block?.key) {
					return true;
				} else if (process.env.NODE_ENV === 'development') {
					console.warn(
						`[BlockList] ${block.alias} has missing or faulty data:`,
						block
					);
				}

				return false;
			});
		},
	},

	methods: {
		getBlockNameFromAlias,

		blockExists(alias) {
			return blockNames.includes(getBlockNameFromAlias(alias));
		},
	},
};
</script>
<style lang="postcss">
div.c-block-list {
	@apply my-80;

	@screen >=768 {
		@apply my-120;
	}
}

,
.vue-portal-target > div.c-block-list,
.vue-portal-target div.c-block-list > div[class*='c-block-list-'] {
	@apply my-40 !important;

	@screen >=768 {
		@apply my-60 !important;
	}
}

,
div.c-block-list > div[class*='c-block-list-'] {
	@apply mb-80;
}

div.c-block-list > picture {
	@apply mb-30;
}

div.c-block-list > div[class*='c-block-list-'] > h2,
div.c-block-list > div[class*='c-block-list-'] > .blockheader,
div.c-block-list > div[class*='c-block-list-'] > span {
	@apply block;
	@apply uppercase;
	@apply text-center;
}

div.c-block-list > div[class*='c-block-list-'] > span {
	@apply text-action;
	@apply text-24;
	@apply mb-15;
	@apply font-Caveat+Brush;
}

div.c-block-list > div[class*='c-block-list-'] > h2,
div.c-block-list > div[class*='c-block-list-'] > .blockheader {
	@apply uppercase;
	@apply text-32;
	@apply text-blue;
	@apply mb-30;
	@apply leading-initial;

	font-weight: 700;
}

div.c-block-list > div[class*='c-block-list-maps-custom'] > h2,
div.c-block-list > div[class*='c-block-list-maps-custom'] > .blockheader {
	@apply text-left;
}

.d-front-page-custom
	div.c-block-list
	div[class^='c-block-list-maps-custom']
	> h2 {
	@apply text-center;
}

div.vue-portal-target div.c-block-list div[class*='c-block-list-'] > h2,
div.vue-portal-target
	div.c-block-list
	div[class*='c-block-list-']
	> .blockheader {
	@apply text-32;
	@apply mb-20;
}

div.vue-portal-target div.c-block-list div[class*='c-block-list-'] > .rte {
	@apply mt-20;
}

@screen >=768 {
	div.c-block-list div[class*='c-block-list-'] > h2,
	div.c-block-list div[class*='c-block-list-'] > .blockheader {
		@apply text-48;
		@apply mb-40;
	}
}
</style>
