import { camelCase, upperFirst } from 'lodash';

const DOCTYPES_BY_SOLUTION_AND_TEMPLATE = {
	// Plop: doctype - add solution
	main: {
		// Plop: doctype - add main doctype
		ContentPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPageCustom.vue'
			),
		LocalAreaPage: () =>
			import(
				/* webpackChunkName: "main__doctypeLocalAreaPage" */ '~/doctypes/main/LocalAreaPageCustom.vue'
			),
		FrontPage: () =>
			import(
				/* webpackChunkName: "main__doctypeFrontPage" */ '~/doctypes/main/FrontPageCustom.vue'
			),
	},
};

export const getDoctypeFromSolutionAndTemplate = (solution, template) =>
	upperFirst(camelCase(`${solution}${template}`));

// format that allows local registration via `components`
export default Object.entries(DOCTYPES_BY_SOLUTION_AND_TEMPLATE).reduce(
	(acc, [solution, doctypesByTemplate]) => ({
		...acc,

		...Object.entries(doctypesByTemplate).reduce(
			(acc, [template, doctype]) => ({
				...acc,

				[getDoctypeFromSolutionAndTemplate(solution, template)]:
					doctype,
			}),
			{}
		),
	}),
	{}
);
