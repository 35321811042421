<template>
	<div class="c-app-footer bg-blue text-white rounded-t-8">
		<div
			class="
				max-w-1440
				mx-auto
				px-20
				py-33
				flex flex-col
				<768:text-14
				>=1024:py-120
				>=1024:px-100
				>=1024:flex-row
				>=1024:justify-between
			"
		>
			<div
				v-if="contactPerson"
				class="contact flex flex-col >=1024:flex-row"
			>
				<div class="flex flex-row items-center mb-20 >=1024:flex-col">
					<div
						v-if="contactPerson.image"
						class="
							rounded-full
							max-w-100
							overflow-hidden
							mr-15
							>=1024:mr-50 >=1024:min-w-275
						"
					>
						<UmbracoImageExt
							class="block w-full"
							:alt="contactPerson.image.altText"
							:source-url="
								contactPerson.image.cropUrl ||
								contactPerson.image.url
							"
							:source-width="contactPerson.image.width"
							:source-height="contactPerson.image.height"
							:widths="[320, 450]"
							:aspect-ratio="1 / 1"
						/>
					</div>
					<h3
						v-if="contactPerson.name"
						class="
							text-20
							>=768:text-24
							font-bold
							uppercase
							>=1024:hidden
						"
						v-html="contactPerson.name"
					></h3>
				</div>
				<div class="flex flex-col <768:text-14">
					<h3
						v-if="contactPerson.name"
						class="
							text-20
							>=768:text-24
							font-bold
							uppercase
							hidden
							>=1024:block
						"
						v-html="contactPerson.name"
					></h3>
					<p
						v-if="contactPerson.text"
						class="
							border-b border-white border-opacity-30
							pb-32
							mb-32
							leading-editorial
							>=1024:mt-25
						"
						v-html="contactPerson.text"
					></p>
					<a
						v-if="contactPerson.phone"
						class="flex items-center"
						:href="`tel:${contactPerson.phone}`"
					>
						<SvgPhone
							class="w-25 h-25 text-white mr-15"
							name="phone"
						/>
						{{ contactPerson.phone }}
					</a>
					<a
						v-if="contactPerson.email"
						class="flex items-center mt-25"
						:href="`mailto:${contactPerson.email}`"
					>
						<SvgEmail
							class="w-25 h-25 text-white mr-15"
							name="email"
						/>
						{{ contactPerson.email }}
					</a>
				</div>
			</div>
			<div class="links flex flex-col">
				<h3
					v-if="footer.some && footer.some.title"
					class="
						text-20
						>=768:text-24
						font-bold
						uppercase
						mt-85
						>=1024:mt-0
					"
					v-html="footer.some.title"
				></h3>
				<nav v-if="footer.some && footer.some.links" class="mt-25">
					<a
						v-for="item in filteredSomeLinks()"
						:key="item[0]"
						target="_blank"
						:href="item[1]"
						class="block capitalize leading-initial"
						v-html="item[1].split('.')[1]"
					></a>
				</nav>
				<nav
					v-if="footer.links && footer.links.links"
					class="mt-30 mb-25 >=1024:mt-auto"
				>
					<a
						v-for="item in footer.links.links"
						:key="item.url"
						:href="item.url"
						:target="item.target"
						class="block leading-initial"
						v-html="item.name || item.url"
					></a>
				</nav>
				<a
					v-if="footer.links && footer.links.showCityOfArms"
					href="https://www.assens.dk/"
					target="_blank"
					aria-label="Link til kommunens hjemmeside"
					title="Link til kommunens hjemmeside"
				>
					<SvgCrestOfArms class="text-white w-160 h-55" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import UmbracoImageExt from '~/components/main/UmbracoImageExt';
import SvgEmail from '~/assets/svgs/email.svg?inline';
import SvgPhone from '~/assets/svgs/phone.svg?inline';
import SvgCrestOfArms from '~/assets/svgs/crestofarms.svg?inline';

export default {
	name: 'AppFooter',

	components: {
		UmbracoImageExt,
		SvgEmail,
		SvgPhone,
		SvgCrestOfArms,
	},

	props: {},

	data() {
		return {
			contactPerson: null,
		};
	},

	computed: {
		...mapGetters({ footer: 'footer' }),
	},

	mounted() {
		this.contactPerson = { ...this.footer.contactPerson };
	},

	methods: {
		filteredSomeLinks() {
			return Object.entries(this.footer.some.links).filter(
				([key, value]) => value !== null
			);
		},
	},
};
</script>
<style>
.c-app-footer .links nav a + a {
	margin-top: 8px;
}

@screen >=1024 {
	.c-app-footer .contact {
		width: 56.85%;
		border: 0 solid #fff;
	}

	.c-app-footer .links {
		width: 29.68%;
		border: 0 solid #fff;
	}
}
</style>
