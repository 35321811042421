<template>
	<UmbracoImage
		v-bind="attrs"
		class="c-umbraco-image-ext"
		:image-class-names="imageClasses"
	/>
</template>

<script>
import UmbracoImage from '@limbo-works/vue-umbraco-image';

export default {
	name: 'UmbracoImageExt',
	components: { UmbracoImage },

	props: {
		...UmbracoImage.props,

		sizes: {
			type: String,
			default: 'auto',
		},

		aspectRatio: {
			type: Number,
			required: false,
		},

		srcWidth: {
			type: Number,
			required: false,
		},

		alt: {
			type: String,
			required: false,
		},

		imageClasses: [String, Array, Object],

		lazyload: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		attrs() {
			const { alt, lazyload, sourceWidth, sourceHeight, aspectRatio } = this;
			const ratio = aspectRatio || sourceWidth / sourceHeight;

			return {
				...this.$props,
				alt,
				loading: lazyload ? 'lazy' : null,

				aspectRatio: ratio,
				srcWidth: this.srcWidth || this.widths[0],
				style: `aspect-ratio: ${ratio}`,
			};
		},
	},
};
</script>
