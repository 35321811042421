<template>
	<Component
		:is="errorPage"
		v-if="errorPage"
		class="l-error"
		v-bind="{ error, ...(content === null ? {} : content) }"
	/>

	<article v-else class="l-error">
		<header>
			<h1 v-text="title"></h1>
		</header>

		<div v-if="isDevelopment">
			<p>
				Note: this is a placeholder, please add an error page via `yarn
				new` prior to going live
			</p>
		</div>
	</article>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import key from '~/assets/js/page-key';

const ERROR_PAGES_BY_SOLUTION = {
	// Plop: error page - add solution
	main: () =>
		import(
			/* webpackChunkName: "main__errorPage" */ '~/doctypes/main/ErrorPage.vue'
		),
};

export default {
	name: 'ErrorLayout',
	key: (route) => key(route),

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	props: {
		error: {
			type: Object,

			default: () => ({
				statusCode: 500,
				message: 'Oops, something went wrong.',
			}),
		},
	},

	data() {
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
		};
	},

	head() {
		return this.content?.meta ?? [];
	},

	computed: {
		...mapGetters(['solution']),
		...mapState({ content: 'error' }),

		errorPage() {
			return ERROR_PAGES_BY_SOLUTION[this.solution];
		},

		title() {
			return `${this.error.statusCode} ${this.error.message}`;
		},
	},

	beforeDestroy() {
		this.$store.commit('SET_ERROR_DATA', null);
	},
};
</script>
