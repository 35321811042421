<template>
	<div
		class="c-secondary-navigation"
		role="navigation"
		aria-label="sekundær navigation"
	>
		<button
			class="
				bg-blue
				text-white
				rounded-full
				h-48
				flex
				items-center
				pl-25
				pr-10
				py-13
			"
			@click="onShow()"
		>
			<span class="mr-20 uppercase font-Rubik font-semibold"
				>{{getAreaName}}</span
			>
			<span
				class="rounded-full w-30 h-30 flex items-center justify-center"
			>
				<svg
					width="12"
					height="8"
					viewBox="0 0 12 8"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.61494 0.496553C2.06075 -0.119212 1.11232 -0.16913 0.496553 0.385059C-0.119212 0.939247 -0.16913 1.88768 0.385059 2.50345L2.61494 0.496553ZM6 6.5L4.88506 7.50345C5.16952 7.81952 5.57477 8 6 8C6.42523 8 6.83048 7.81952 7.11494 7.50345L6 6.5ZM11.6149 2.50345C12.1691 1.88768 12.1192 0.939247 11.5034 0.385059C10.8877 -0.16913 9.93925 -0.119212 9.38506 0.496553L11.6149 2.50345ZM0.385059 2.50345L4.88506 7.50345L7.11494 5.49655L2.61494 0.496553L0.385059 2.50345ZM7.11494 7.50345L11.6149 2.50345L9.38506 0.496553L4.88506 5.49655L7.11494 7.50345Z"
					/>
				</svg>
			</span>
		</button>

		<Portal v-if="isOpen" to="SpaOverlay">
			<div
				class="
					fixed
					top-0
					right-0
					w-full
					h-full
					bg-blue
					text-white
					z-30
				"
			>
				<div
					class="
						relative
						w-full
						max-w-1440
						mx-auto
						py-100
						>=768:py-150
					"
				>
					<button
						class="
							fixed
							>=768:absolute
							top-30
							right-20
							>=768:top-33 >=768:right-33
							rounded-full
							bg-white
							w-48
							h-48
							flex
							justify-center
							items-center
							text-blue
							transform
							hover:rotate-180
							transition
							duration-500
							ease-in-out
							>=768:right-40
						"
						@click="onClose()"
					>
						<SvgClose class="w-12 h-12" />
					</button>
					<nav
						class="
							c-secondary-navigation__nav
							h-full
							w-full
							gap-50
							px-20
							>=768:py-13 >=768:px-50 >=768:px-100
						"
					>
						<NuxtLinkExtVue
							v-for="item in items"
							:key="item.id"
							:to="item.url"
							class="
								text-white
								uppercase
								font-bold
								block
								leading-initial
								pt-24
								text-16
								first:pt-0
								>=768:leading-24 >=768:pt-15 >=768:text-20
							"
							@click.native="onClose()"
							v-html="item.title"
						></NuxtLinkExtVue>
					</nav>
				</div>
			</div>
		</Portal>
	</div>
</template>

<script>
import NuxtLinkExtVue from '~/citi-baseline/components/NuxtLinkExt.vue';
import SvgClose from '~/assets/svgs/close.svg?inline';

export default {
	name: 'SecondaryNavigation',

	components: {
		NuxtLinkExtVue,
		SvgClose,
	},

	props: {
		items: {
			type: Array,
			default: () => [],
		},
		sticky: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isOpen: false,
			index: 0,
		};
	},

	created() {
		this.$on('overlay.close', this.onClose);
	},

	computed: {
		getAreaName() {
		  if (this.$store.state.site.id === 1231) {
			return 'Lokalområder';
		  } else {
			return 'Local Areas';
		  }
		},
	},

	methods: {
		onClose() {
			this.isOpen = false;
		},
		onShow() {
			this.isOpen = true;
		},
	},
};
</script>
<style lang="postcss">
.c-secondary-navigation > button {
	font-weight: 600;
}

.c-secondary-navigation > button:hover span:last-child {
	@apply bg-white;
	@apply text-blue;
}

@screen >=768 {
	.c-secondary-navigation__nav {
		columns: 2;
	}
}

@screen >=1024 {
	.c-secondary-navigation__nav {
		columns: 3;
	}
}
</style>
