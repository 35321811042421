<!--
	Last modified: 2022/02/21 11:27:01
-->
<template>
	<div v-once ref="container" class="spa-script"></div>
</template>

<script>
export default {
	name: 'SpaScript',

	beforeRouteLeave(to) {
		window.location.assign(to.path);
	},

	props: {
		html: {
			type: String,
			required: true,
		},
	},

	mounted() {
		const postscribe = require('postscribe');
		postscribe(this.$refs.container, this.html);
	},
};
</script>
