<template>
	<div
		class="
			c-app-header
			max-w-1440
			mx-auto
			flex
			justify-between
			px-20
			>=1024:px-30
			mt-30
			mb-80
			>=1024:mb-150
		"
	>
		<PrimaryNavigation
			:id="`primary-navigation`"
			:ref="`primary-navigation`"
			:items="navigation"
			role="region"
			:aria-labelledby="`primary-navigation-control`"
			:contactpersons="contactPerson"
		/>
		<SecondaryNavigation
			v-if="localAreas && localAreas.length > 0"
			:id="`secondary-navigation`"
			:ref="`secondary-navigation`"
			:items="localAreas"
			role="region"
			:aria-labelledby="`secondary-navigation-control`"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import PrimaryNavigation from '~/components/main/PrimaryNavigation';
import SecondaryNavigation from '~/components/main/SecondaryNavigation';
import ContactButton from '~/components/main/ContactButton';

export default {
	name: 'AppHeader',

	components: {
		PrimaryNavigation,
		SecondaryNavigation,
		ContactButton,
	},

	data() {
		return {
			activePrimaryNavigationSectionId: null,
			contactPerson: null,
		};
	},

	computed: {
		...mapGetters({ navigation: 'primaryNavigation' }),
		...mapGetters({ localAreas: 'secondaryNavigation' }),
		...mapGetters({ footer: 'footer' }),
	},

	mounted() {
		this.contactPerson = [{ ...this.footer.contactPerson }];
	},
};
</script>
