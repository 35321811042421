import Vue from 'vue';

Vue.directive('tracking', {
	// When the bound element is inserted into the DOM...
	bind(el, binding) {
		el.addEventListener('click', () => {
			if (typeof window._sz !== 'undefined') {
				let trimmedValues;
				const values = binding.value;
				/**
				 * Split attribute values by ","
				 * Trim whitespaces
				 * Filter out empty values
				 */
				trimmedValues = values.split(',').map((i) => i.trim());
				trimmedValues = trimmedValues.filter((i) => i !== '');

				/**
				 * Add "event" to array
				 * required
				 */
				trimmedValues.unshift('event');

				/**
				 * Push
				 * Will make a request to SiteImprove
				 */
				if (trimmedValues.length >= 3) {
					window._sz.push(trimmedValues);
				}
			}
		});
	},
});
