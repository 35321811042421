<template>
	<div
		v-if="data"
		:class="[
			'c-media overflow-hidden rounded-8',
			pagetype == 'FrontPage' ? '>=768:-mt-90' : 'mt-0',
		]"
	>
		<UmbracoImageExt
			v-if="acceptedFormats(data.extension) && data.type == 'Image'"
			:alt="data.altText"
			:source-url="data.cropUrl || data.path"
			:source-width="data.width"
			:source-height="data.height"
			:widths="[320, 417, 768, 1440]"
			:aspect-ratio="mobile ? 1 / 1 : 16 / 9"
			:lazyload="lazyload"
			:sizes="
				imageSizes({
					default: '92vw',
					768: '35vw',
				})
			"
		/>
		<div
			v-if="data.type == 'skyfishMediaVideo' && data.skyfishCode"
			class="relative h-0 pb-16/9"
			v-html="data.skyfishCode"
		></div>

		<div
			v-if="data.type == 'umbracoMediaVideo' && data.videoUrl"
			class="w-full h-full relative <768:hidden"
		>
			<video
				ref="heroVideo_large"
				playsInline
				loop
				preload="auto"
				crossOrigin="anonymous"
				style="objectfit: cover"
				class="w-full mobile:h-500 mobile:contain"
				:poster="data.cropUrl"
				v-bind="{ autoplay, mute: autoplay }"
			>
				<source :src="data.videoUrl" type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			<button
				:aria-label="videoIsPlaying ? 'Stop film' : 'Afspil film'"
				class="
					absolute
					left-20
					bottom-20
					>=768:mb-20 >=768:ml-32
					z-10
					w-48
					h-48
					overflow-hidden
					rounded-full
					bg-background
					text-blue
					hover:opacity-75
					transition
					duration-200
					ease-smooth-line
					focus:outline-none
				"
				@click="toggleVideo('large')"
			>
				<SvgPause v-if="videoIsPlaying" />
				<SvgPlay v-else />
			</button>
		</div>
		<div
			v-if="data.type == 'umbracoMediaVideo' && data.videoUrl"
			class="w-full h-0 relative pb-full >=768:hidden"
		>
			<video
				ref="heroVideo_small"
				playsInline
				loop
				preload="auto"
				crossOrigin="anonymous"
				style="object-fit: cover"
				class="absolute w-full h-full"
				:poster="data.cropUrl"
				v-bind="{ autoplay, mute: autoplay }"
			>
				<source :src="data.videoUrl" type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			<button
				:aria-label="videoIsPlaying ? 'Stop film' : 'Afspil film'"
				class="
					absolute
					left-20
					bottom-20
					>=768:mb-20 >=768:ml-32
					z-10
					w-48
					h-48
					overflow-hidden
					rounded-full
					bg-background
					text-blue
					hover:opacity-75
					transition
					duration-200
					ease-smooth-line
					focus:outline-none
				"
				@click="toggleVideo('small')"
			>
				<SvgPause v-if="videoIsPlaying" />
				<SvgPlay v-else />
			</button>
		</div>
	</div>
</template>

<script>
import { imageSizes } from '@limbo-works/vue-umbraco-image';

import UmbracoImageExt from '~/components/main/UmbracoImageExt';
import SvgPlay from '~/assets/svgs/play.svg?inline';
import SvgPause from '~/assets/svgs/pause.svg?inline';

export default {
	name: 'Media',

	components: {
		UmbracoImageExt,
		imageSizes,
		SvgPlay,
		SvgPause,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
		pagetype: {
			type: String,
			required: false,
		},
		autoplay: {
			type: Boolean,
			required: false,
			default: false,
		},
		statistic: {
			type: Boolean,
			default: true,
		},
		functional: {
			type: Boolean,
			default: true,
		},
		lazyload: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			mobile: false,
			videoIsPlaying: false,
		};
	},

	computed: {
		isVideoPlaying() {
			const player = this.$refs.heroVideo;

			return (
				player.currentTime > 0 &&
				!player.paused &&
				!player.ended &&
				player.readyState > 2
			);
		},
		computedCookies() {
			const { statistic, functional } = this;
			const cookies = { statistic, functional };
			return Object.keys(cookies).filter((e) => cookies[e]);
		},
	},

	mounted() {
		const player = this.$refs.heroVideo;

		setInterval(() => {
			if (
				player &&
				player.currentTime > 0 &&
				!player.paused &&
				!player.ended &&
				player.readyState > 2
			) {
				this.videoIsPlaying = true;
			}
		}, 500);

		window.addEventListener('resize', this.onResize);
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		toggleVideo(format) {
			this.videoIsPlaying = !this.videoIsPlaying;
			if (this.videoIsPlaying) {
				this.$refs[`heroVideo_${format}`]?.play();
			} else {
				this.$refs[`heroVideo_${format}`]?.pause();
			}
		},
		acceptedFormats(format) {
			const formats = ['jpg', 'png'];
			return formats.includes(format);
		},
		onResize() {
			const { innerWidth } = window;
			this.mobile = innerWidth < 768;
		},
		imageSizes,
	},
};
</script>
<style lang="postcss">
.c-media iframe {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply w-full;
	@apply h-full;
}
</style>
