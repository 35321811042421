<template>
	<svg
		width="19"
		height="15"
		viewBox="0 0 19 15"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		class="c-burger"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 1.5C0 0.671573 0.671573 0 1.5 0H17.5C18.3284 0 19 0.671573 19 1.5C19 2.32843 18.3284 3 17.5 3H1.5C0.671573 3 0 2.32843 0 1.5ZM0 13.5C0 12.6716 0.671573 12 1.5 12H17.5C18.3284 12 19 12.6716 19 13.5C19 14.3284 18.3284 15 17.5 15H1.5C0.671573 15 0 14.3284 0 13.5ZM1.5 6C0.671573 6 0 6.67157 0 7.5C0 8.32843 0.671573 9 1.5 9H17.5C18.3284 9 19 8.32843 19 7.5C19 6.67157 18.3284 6 17.5 6H1.5Z"
		/>
	</svg>
</template>

<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Burger',

	props: {},

	data() {
		return {};
	},
};
</script>
