<!--
	Last modified: 2022/02/22 18:14:12
-->
<script>
import deepmerge from 'deepmerge';

// The main theme should be configured (and always exists) at ~/assets/js/theme-configuration.js
import {
	defaultConfig,
	restructureFontSizeObject,
} from '~/assets/js/theme-configuration.js';

const cloneDeep = require('clone-deep');

const minify = defaultConfig.minify;

/*
	This is the component responsible for configuring the theme
	of the solution. The component takes basis in a default theme,
	usually the one used on a main solution. But it can* also take
	data from the site object, which allow the backend to overwrite
	the theme.

	The component does also also allow for a configuration to be
	passed in through a prop, but this shouldn't be the way, and
	is mainly done for future demo site.

	*The handling of getting-from-the-site-object is not developed yet
	Also colors aren't currently handled.
*/

export default {
	name: 'ThemeConfiguration',
	key: 'ThemeConfiguration',

	props: {
		config: {
			type: Object,
			default: () => ({}),
		},
	},

	head() {
		return {
			style: [
				this.cssText && {
					hid: 'theme-configuration',
					cssText: this.cssText,
					type: 'text/css',
				},
			].filter(Boolean),
		};
	},

	computed: {
		compConfig() {
			// Overwrite by property
			if (Object.keys(this.config || {}).length) {
				return deepmerge(defaultConfig, this.config);
			}

			// Handle fetched data / site data
			// ...from this.$store.state.site.theme or something like that

			// Default to the defaultConfig
			return cloneDeep(defaultConfig);
		},

		cssText() {
			const {
				compConfig: config,
				extractRules: extract,
				extractFontRules,
			} = this;
			const { baseFontSize, mdViewport } = config;
			let rules = [
				// extractColorRules('color', config?.colors),
				extract('layout', config?.layout),
				extract('spacing', config?.spacing),
				extractFontRules(config?.fontSize),
				extract('borderRadius', config?.borderRadius),
			];
			let lgScreenRules = rules
				.reduce((arr, obj) => {
					arr.push(...obj.lgScreenRules);
					return arr;
				}, [])
				.filter(Boolean);
			rules = rules
				.reduce((arr, obj) => {
					arr.push(...obj.rules);
					return arr;
				}, [])
				.filter(Boolean);

			// Apply root around rules and indent
			if (rules.length) {
				if (!minify) {
					rules = rules.map((rule) => `  ${rule}`);
				}
				rules.unshift(':root {');
				rules.push('}');
			}

			// Apply media query and root around rules and indent
			if (lgScreenRules.length) {
				// Root
				if (!minify) {
					lgScreenRules = lgScreenRules.map((rule) => `  ${rule}`);
				}
				lgScreenRules.unshift(':root {');
				lgScreenRules.push('}');

				// Media query
				if (!minify) {
					lgScreenRules = lgScreenRules.map((rule) => `  ${rule}`);
				}
				lgScreenRules.unshift(
					`@media screen and (min-width: ${
						Math.round((mdViewport / baseFontSize) * 1000) / 1000
					}em) {`
				);
				lgScreenRules.push('}');
			}

			if (minify) {
				return [...rules, ...lgScreenRules].join('');
			}
			return [...rules, ...lgScreenRules].join('\n');
		},
	},

	methods: {
		// Extracting font rules from the config
		extractFontRules(object) {
			object = typeof object === 'object' ? object : {};

			// Restructure the object
			object = restructureFontSizeObject(object);

			// Extract rules
			const { baseFontSize } = this.compConfig;
			const returnObject = {
				rules: [],
				lgScreenRules: [],
			};

			// Extract font sizes and letter spacings as rem
			['fontSize', 'letterSpacing'].forEach((key) => {
				if (object[key]) {
					const extracted = this.extractRules(
						key,
						object[key],
						'rem',
						(value) => {
							return (
								Math.round(
									(Number(value) / baseFontSize) * 1000
								) / 1000
							);
						}
					);
					returnObject.rules.push(...extracted.rules);
					returnObject.lgScreenRules.push(...extracted.lgScreenRules);
				}
			});

			// Extract line heights as unitless
			['lineHeight'].forEach((key) => {
				if (object[key]) {
					const extracted = this.extractRules(key, object[key], '');
					returnObject.rules.push(...extracted.rules);
					returnObject.lgScreenRules.push(...extracted.lgScreenRules);
				}
			});

			return returnObject;
		},

		// Extracting css rules from the config
		extractRules(
			prefix,
			object,
			unit = 'px',
			transformation = (value) => Number(value)
		) {
			object = typeof object === 'object' ? object : {};
			const rules = [];
			const lgScreenRules = [];

			for (const name in object) {
				const subObject = object[name];

				// First the general rules
				for (const suffix in subObject) {
					const value = subObject[suffix];
					rules.push(
						`--theme-${prefix}-${name}--${suffix}: ${transformation(
							value
						)}${unit};`
					);
				}

				// Then the scaling rules
				const doScalingRule = ['sm', 'md', 'lg'].every((key) => {
					return Object.keys(subObject).includes(key);
				});
				const { smViewport, mdViewport, lgViewport } = this.compConfig;
				if (doScalingRule) {
					const { sm, md, lg } = subObject;

					// This one is for smaller screens
					const f1 = (x) => {
						const m = (md - sm) / (mdViewport - smViewport);
						const b = sm - m * smViewport;
						return Math.round((m * x + b) * 1000) / 1000;
					};
					if (sm === md) {
						rules.push(
							`--theme-${prefix}-${name}: ${transformation(
								sm
							)}${unit};`
						);
					} else {
						const min = Math.min(sm, md);
						const max = Math.max(sm, md);
						const mid = md;
						rules.push(
							`--theme-${prefix}-${name}: clamp(${transformation(
								min
							)}${unit}, ${transformation(
								f1(0) + (unit === 'rem' ? mid : 0)
							)}${unit} + ${
								Math.round(
									((max - min) / (mdViewport - smViewport)) *
										100000
								) / 1000
							}vw - ${
								unit === 'rem' ? mid : 0
							}px, ${transformation(
								max + (unit === 'rem' ? mid : 0)
							)}${unit} - ${unit === 'rem' ? mid : 0}px);`
								.split(' - 0px')
								.join('')
						);
					}

					// This one is for larger screens (if lg is not the same as md)
					if (lg !== md) {
						const f2 = (x) => {
							const m = (lg - md) / (lgViewport - mdViewport);
							const b = md - m * mdViewport;
							return Math.round((m * x + b) * 1000) / 1000;
						};

						const min = Math.min(md, lg);
						const max = Math.max(md, lg);
						const mid = md;
						lgScreenRules.push(
							`--theme-${prefix}-${name}: clamp(${transformation(
								min + (unit === 'rem' ? mid : 0)
							)}${unit} - ${
								unit === 'rem' ? mid : 0
							}px, ${transformation(
								f2(0) + (unit === 'rem' ? mid : 0)
							)}${unit} + ${
								Math.round(
									((max - min) / (lgViewport - mdViewport)) *
										100000
								) / 1000
							}vw - ${
								unit === 'rem' ? mid : 0
							}px, ${transformation(max)}${unit});`
								.split(' - 0px')
								.join('')
						);
					}
				}
			}

			// Return rules
			if (minify) {
				return {
					rules,
					lgScreenRules,
				};
			}
			return {
				rules: rules.length ? [`/* ${prefix} */`, ...rules] : [],
				lgScreenRules: lgScreenRules.length
					? [`/* ${prefix} */`, ...lgScreenRules]
					: [],
			};
		},
	},

	// The ThemeConfiguration doesn't insert anything new to the DOM itself, other than the styles in head()
	render() {
		if (!this.$slots.default?.length) {
			return null;
		}
		try {
			return this.$slots.default[0];
		} catch (e) {
			throw new Error(
				'ThemeConfiguration.vue can only render one child component.'
			);
		}
	},
};
</script>
