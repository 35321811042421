<template>
	<article class="d-front-page-custom relative">
		<HeroItem
			:data="heroMedia"
			:pagetype="template"
			:autoplay="heroAutoplay"
		/>
		<ContactButton :content="contactpersons" />
		<BlockList :block-list="contentElements" />
	</article>
</template>

<script>
import HeroItem from '~/components/main/HeroItem';
import BlockList from '~/components/main/BlockList';
import ContactButton from '~/components/main/ContactButton';
export default {
	name: 'MainFrontPageCustom',

	components: {
		HeroItem,
		BlockList,
		ContactButton,
	},

	inheritAttrs: false,

	props: {
		title: String,
		heroMedia: Object,
		heroAutoplay: Boolean,
		contentElements: Array,
		template: String,
		contactpersons: Array,
	},

	data() {
		return {};
	},
};
</script>
