import { camelCase, upperFirst } from 'lodash';

const BLOCKS_BY_SOLUTION_AND_ALIAS = {
	// Plop: block-list - add solution
	main: {
		// Plop: block-list - add main block
		Image: () =>
			import(
				/* webpackChunkName: "main__blockListImage" */ '~/components/main/BlockList/blocks/BlockListImageCustom.vue'
			),
		Text: () =>
			import(
				/* webpackChunkName: "main__blockListText" */ '~/components/main/BlockList/blocks/BlockListTextCustom.vue'
			),
		Maps: () =>
			import(
				/* webpackChunkName: "main__blockListMaps" */ '~/components/main/BlockList/blocks/BlockListMapsCustom.vue'
			),
		InfoBoxes: () =>
			import(
				/* webpackChunkName: "main__blockListInfoBoxes" */ '~/components/main/BlockList/blocks/BlockListInfoBoxesCustom.vue'
			),
		CustomCtaBoxes: () =>
			import(
				/* webpackChunkName: "main__blockListCustomCtaBoxes" */ '~/components/main/BlockList/blocks/BlockListCustomCtaBoxesCustom.vue'
			),
		Media: () =>
			import(
				/* webpackChunkName: "main__blockListMedia" */ '~/components/main/BlockList/blocks/BlockListMediaCustom.vue'
			),
		Cases: () =>
			import(
				/* webpackChunkName: "main__blockListCases" */ '~/components/main/BlockList/blocks/BlockListCasesCustom.vue'
			),
	},
};

export const getBlockNameFromAlias = (alias) =>
	`BlockList${upperFirst(camelCase(alias))}`;

export const getBlocksBySolution = (solution) =>
	Object.entries(BLOCKS_BY_SOLUTION_AND_ALIAS[solution] ?? {}).reduce(
		(acc, [alias, component]) => ({
			...acc,

			[getBlockNameFromAlias(alias)]: component,
		}),
		{}
	);
