<template>
	<div
		v-if="areaCases"
		class="
			c-block-list-cases-custom
			text-center text-blue
			<768:w-screen <768:-ml-20
		"
	>
		<span v-if="byline" v-html="byline"></span>
		<h2 v-if="title" v-html="title"></h2>
		<SwiperWrap
			ref="slider"
			:move-by="1"
			class="relative"
			:items-wrapper-class-names="[
				'flex w-full u-hide-scrollbar items-start gap-15 >=768:gap-50',
				'snap snap-x snap-mandatory',
				'overflow-x-auto',
			]"
		>
			<!-- Items -->
			<template #items>
				<button
					v-for="(item, i) in areaCases"
					:key="item.key"
					ref="mobileItems"
					v-tracking="
						`Case, Click, ${item.content.caseTitle} - ${getPath}`
					"
					:class="[
						'caseItem snap-start <768:min-w-4/5 >=768:w-1/3 text-left',
						i == 0 ? 'ml-20' : null,
						i == areaCases.length - 1 ? 'mr-20' : null,
					]"
					@click="onShow(i)"
				>
					<div class="relative overflow-hidden rounded-8 mb-25">
						<UmbracoImageExt
							class="block w-full"
							:alt="item.content.caseMedia.altText"
							:source-url="
								item.content.caseMedia.cropUrl ||
								item.content.caseMedia.path
							"
							:source-width="item.content.caseMedia.width"
							:source-height="item.content.caseMedia.height"
							:widths="[320, 417, 575]"
							:aspect-ratio="16 / 9"
						/>
						<div
							class="
								absolute
								top-1/2
								left-1/2
								w-50
								h-50
								bg-white
								rounded-full
								flex
								items-center
								z-20
							"
						>
							<svg
								class="absolute left-18 right-14"
								width="16"
								height="20"
								viewBox="0 0 16 20"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15.1094 11.6639L3.10938 19.6633C2.80825 19.8644 2.45814 19.98 2.09645 19.9976C1.73475 20.0153 1.37506 19.9343 1.05578 19.7635C0.736507 19.5926 0.469642 19.3383 0.283689 19.0276C0.0977373 18.7168 -0.000317854 18.3615 7.74059e-07 17.9994V2.00063C-0.000317854 1.63853 0.0977373 1.28315 0.283689 0.972443C0.469642 0.661733 0.736507 0.407367 1.05578 0.236511C1.37506 0.0656557 1.73475 -0.0152705 2.09645 0.0023754C2.45814 0.0200213 2.80825 0.135577 3.10938 0.3367L15.1094 8.33607C15.3833 8.51868 15.608 8.7661 15.7633 9.05637C15.9187 9.34664 16 9.67077 16 10C16 10.3292 15.9187 10.6534 15.7633 10.9436C15.608 11.2339 15.3833 11.4813 15.1094 11.6639Z"
								/>
							</svg>
						</div>
					</div>
					<h3
						v-if="item.content.caseTitle"
						class="
							leading-basic
							uppercase
							text-20
							>=768:text-24
							font-bold
						"
						v-html="item.content.caseTitle"
					></h3>
					<p
						v-if="item.content.caseTeaser"
						class="mt-5 >=768:mt-15"
						v-html="item.content.caseTeaser"
					></p>
				</button>
			</template>
		</SwiperWrap>
		<Portal v-if="isOpen" to="SpaOverlay">
			<div
				class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30"
				@click="onClose(index)"
			>
				<div
					class="
						fixed
						top-0
						right-0
						w-full
						h-full
						bg-white
						z-20
						p-20
						>=768:w-half
					"
					@click.stop
				>
					<button
						class="
							absolute
							top-30
							right-20
							>=768:top-33 >=768:right-33
							rounded-full
							bg-white
							z-20
							w-48
							h-48
							flex
							justify-center
							items-center
							transform
							hover:rotate-180
							transition
							duration-500
							ease-in-out
						"
						@click="onClose(index)"
					>
						<SvgClose class="w-12 h-12" />
					</button>
					<Media :data="areaCases[index].content.caseMedia" />
					<div class="mt-20">
						<h3
							v-if="areaCases[index].content.caseTitle"
							class="
								leading-basic
								uppercase
								text-20
								>=768:text-24
								font-bold
							"
							v-html="areaCases[index].content.caseTitle"
						></h3>
						<p
							v-if="areaCases[index].content.caseTeaser"
							class="mt-5 >=768:mt-15"
							v-html="areaCases[index].content.caseTeaser"
						></p>
					</div>
				</div>
			</div>
		</Portal>
	</div>
</template>

<script>
import Media from '~/components/main/Media';
import UmbracoImageExt from '~/components/main/UmbracoImageExt';
import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import SvgClose from '~/assets/svgs/close.svg?inline';

export default {
	name: 'BlockListCasesCustom',

	components: {
		UmbracoImageExt,
		Media,
		SwiperWrap,
		SvgClose,
	},

	inheritAttrs: true,

	props: {
		byline: String,
		title: String,
		areaCases: Array,
	},

	data() {
		return {
			isOpen: false,
			index: 0,
		};
	},

	computed: {
		count() {
			return (this?.areaCases).length;
		},
		getPath() {
			return this.$store.state.route.fullPath;
		},
	},

	created() {
		this.$on('overlay.close', this.onClose);
	},

	methods: {
		onClose() {
			this.isOpen = false;
		},
		onShow(index) {
			this.index = index;
			this.isOpen = true;
		},
	},
};
</script>
<style lang="postcss">
/*! purgecss start ignore */
.u-hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.u-hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.c-block-list-cases-custom button {
	-webkit-appearance: none;
}

.c-block-list-cases-custom .caseItem img,
.c-block-list-cases-custom .caseItem > div::after {
	transition: all 0.25s ease-in-out;
}

.c-block-list-cases-custom .caseItem:hover picture + div {
	@apply bg-blue !important;
	@apply text-white;
}

.c-block-list-cases-custom .caseItem div div {
	transform: translate(-50%, -50%);
}

.c-block-list-cases-custom .caseItem > div::after {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply w-full;
	@apply h-full;
	@apply block;
	@apply z-10;
	@apply bg-black;
	@apply bg-opacity-20;

	content: ' ';
}

.c-block-list-cases-custom .caseItem:hover > div::after {
	@apply bg-opacity-0;
}
/*! purgecss end ignore */
</style>
