<template>
	<div class="c-block-list-media-custom">
		<span v-if="byline" v-html="byline"></span>
		<h2 v-if="title" v-html="title"></h2>
		<Media v-if="media" :data="media" />
	</div>
</template>

<script>
import Media from '~/components/main/Media';

export default {
	name: 'BlockListMediaCustom',

	components: {
		Media,
	},

	inheritAttrs: true,

	props: {
		byline: String,
		title: String,
		media: Object,
	},

	data() {
		return {
			videoIsPlaying: false,
		};
	},

	methods: {
		toggleVideo(id) {
			this.videoIsPlaying = !this.videoIsPlaying;
			if (this.videoIsPlaying) {
				this.$refs[`m${id}`]?.play();
			} else {
				this.$refs[`m${id}`]?.pause();
			}
		},
	},
};
</script>
<style>
.c-block-list-media-custom > div {
	border-radius: 8px;
}

.c-block-list-media-custom > div.video {
	@apply h-0;
	@apply w-full;
	@apply pb-16/9;
}

.c-block-list-media-custom iframe {
	@apply absolute;
	@apply top-0;
	@apply left-0;
	@apply w-full;
	@apply h-full;
}
</style>
