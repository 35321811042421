const cloneDeep = require('clone-deep');

/*
	The default config should typically be set as the values of
	the main solution. These can then be overwritten by the values
	coming from backend (handled in the ThemeConfiguration.vue component).
*/
const defaultConfig = {
	// Testing only
	minify: true, // Can be turned to false for a more readable output in the style tag

	// Setup
	baseFontSize: 16, // For rem conversion
	smViewport: 375, // Lowest value clamp
	mdViewport: 1440, // Midpoint (used for both low-clamp and high-clamp)
	lgViewport: 1920, // Highest value clamp

	// Rules
	colors: {
		/* to be added and treated differently than other values */
	},

	layout: {
		margin: {
			sm: 16,
			md: 96,
			lg: 124,
		},
		gutter: {
			sm: 16,
			md: 24,
			lg: 32,
		},
	},

	spacing: {
		xs: {
			sm: 6,
			md: 8,
			lg: 12,
		},
		sm: {
			sm: 12,
			md: 16,
			lg: 20,
		},
		md: {
			sm: 18,
			md: 24,
			lg: 32,
		},
		lg: {
			sm: 24,
			md: 32,
			lg: 44,
		},
		xl: {
			sm: 36,
			md: 48,
			lg: 64,
		},
		'2xl': {
			sm: 48,
			md: 64,
			lg: 88,
		},
		'3xl': {
			sm: 72,
			md: 96,
			lg: 128,
		},
	},

	// fontSize (and lineHeight and letterSpacing) is a special setup, as special rules are generated
	fontSize: {
		h1: {
			fontSize: {
				sm: 28,
				md: 48,
				lg: 64,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: -0.5,
				md: -1,
				lg: -0.5,
			},
		},
		h2: {
			fontSize: {
				sm: 22,
				md: 36,
				lg: 48,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: -0.25,
				md: -0.5,
				lg: -1,
			},
		},
		h3: {
			fontSize: {
				sm: 16,
				md: 24,
				lg: 32,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: -0.5,
				lg: -0.5,
			},
		},
		h4: {
			fontSize: {
				sm: 14,
				md: 20,
				lg: 26,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		body: {
			fontSize: {
				sm: 16,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: -0.2,
				md: -0.2,
				lg: -0.25,
			},
		},
		button: {
			fontSize: {
				sm: 14,
				md: 16,
				lg: 20,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.5,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0.25,
				md: 0.25,
				lg: 0.25,
			},
		},
		caption: {
			fontSize: {
				sm: 10,
				md: 12,
				lg: 16,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0.25,
				md: 0.25,
				lg: 0.5,
			},
		},
		overline: {
			fontSize: {
				sm: 8,
				md: 10,
				lg: 12,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 1.5,
				md: 1.5,
				lg: 2,
			},
		},
	},

	borderRadius: {
		/* to be added */
	},
};

module.exports = {
	defaultConfig,
	makeThemeUtilities,
	restructureFontSizeObject,
};

// Down here we have some practical functions (shouldn't be altered)
// Function to generate the tailwind utility values.
function makeThemeUtilities() {
	const config = cloneDeep(defaultConfig);
	if (config.fontSize) {
		const fontSizeUtils = restructureFontSizeObject(config.fontSize);
		delete config.fontSize;
		Object.assign(config, fontSizeUtils);
	}
	const obj = {};
	Object.keys(config).forEach((key) => {
		if (typeof config[key] === 'object') {
			Object.keys(config[key]).forEach((subKey) => {
				obj[key] = obj[key] || {};
				obj[key][
					subKey
				] = `var(--theme-${key}-${subKey}, var(--theme-${key}-${subKey}--sm))`;

				// FontSize only:
				if (key === 'fontSize') {
					const lineHeight = config?.lineHeight?.[subKey];
					const letterSpacing = config?.letterSpacing?.[subKey];
					if (lineHeight || letterSpacing) {
						const extras = {};
						if (lineHeight) {
							extras.lineHeight = `var(--theme-lineHeight-${subKey}, var(--theme-lineHeight-${subKey}--sm))`;
						}
						if (letterSpacing) {
							extras.letterSpacing = `var(--theme-letterSpacing-${subKey}, var(--theme-letterSpacing-${subKey})--sm)`;
						}
						obj[key][subKey] = [
							`var(--theme-${key}-${subKey}, var(--theme-${key}-${subKey}--sm))`,
							extras,
						];
					}
				}
			});
		}
	});
	return obj;
}

// Function to restructure font size object
function restructureFontSizeObject(object) {
	return Object.keys(typeof object === 'object' ? object : {}).reduce(
		(newObject, key) => {
			['fontSize', 'lineHeight', 'letterSpacing'].forEach((property) => {
				if (object[key][property]) {
					// Make base object if it doesn't exist
					if (!newObject[property]) {
						newObject[property] = {};
					}

					// Add the sub objects
					newObject[property][key] = object[key][property];
				}
			});

			return newObject;
		},
		{}
	);
}
