<template>
	<div v-if="mapSettings" class="c-block-list-maps-custom">
		<h2 v-if="mapTitle" v-html="mapTitle"></h2>

		<div
			ref="target"
			class="
				relative
				overflow-hidden
				flex flex-col
				h-720
				>=768:h-695 >=768:max-h-695 >=768:flex-row
			"
		></div>

		<PostScribeScript
			v-if="isLoaded"
			html="<script src='https://widget.cdn.septima.dk/latest/widgetconfig.js' async></script>"
		/>
	</div>
</template>

<script>
// import CookieInformationBlocker from '~/components/main/CookieInformationBlockerCustom';
import PostScribeScript from '~/citi-baseline/components/PostScribeScript';

export default {
	name: 'BlockListMapsCustom',
	components: { PostScribeScript },
	inheritAttrs: true,

	props: {
		mapTitle: {
			type: String,
			required: false,
		},
		mapSettings: {
			type: Object,
			required: true,
		},
		statistic: {
			type: Boolean,
			default: true,
		},
		functional: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isLoaded: false,
		};
	},
	computed: {
		computedCookies() {
			const { statistic, functional } = this;
			const cookies = { statistic, functional };
			return Object.keys(cookies).filter((e) => cookies[e]);
		},
	},
	watch: {
		// whenever question changes, this function will run
		visible(original, old) {
			if (original) {
				if (this.mapSettings) {
					const { umbracoFile } = this.mapSettings;

					this.$axios
						.get(umbracoFile)
						.then(this.onLoad)
						.catch((error) => console.warn(error));
				}
			}
		},
	},
	mounted() {
		if (this.mapSettings) {
			const { umbracoFile } = this.mapSettings;

			this.$axios
				.get(umbracoFile)
				.then(this.onLoad)
				.catch((error) => console.warn(error));
		}
	},
	methods: {
		onLoad(data) {
			if (this.$refs?.target) {
				const { target } = this.$refs;
				const { umbracoFile } = this.mapSettings;
				const response = JSON.parse(data.request.response);
				target.innerHTML = '';

				/**
				 * Inserts main map dom-element.
				 */
				const el = document.createElement('div');
				el.setAttribute('data-widget-user', 'assens');
				el.setAttribute('data-widget-url', umbracoFile);
				target.appendChild(el);

				/**
				 * Inserts dom-elements based on the
				 * map controls.
				 */
				const defaults = {
					table: false,
				};

				if (response?.map?.controls) {
					const { controls } = response.map;
					const options = controls.reduce(
						(acc, option) =>
							acc.concat(
								Object.keys(option).filter((key) => {
									return (
										option[key].detach &&
										!option[key].disabled
									);
								})
							),
						[]
					);

					options.forEach((option) => {
						const el = document.createElement('div');
						el.setAttribute('data-widget-detach', option);

						target.appendChild(el);
					});
				}
			}

			this.isLoaded = true;
		},
	},
};
</script>
<style lang="postcss">
/*! purgecss start ignore */
.c-block-list-maps-custom div.widget-container {
	height: 335px;
	max-height: 335px;
}

.c-block-list-maps-custom div.widget-map {
	height: 292px;
	max-height: 292px;
}

.c-block-list-maps-custom div.widget-detached,
.c-block-list-maps-custom div.widget-table {
	height: 385px;
	max-height: 385px;
}

.c-block-list-maps-custom div.widget-detached {
	@apply bg-white;
}

@screen <768 {
	.d-front-page-custom .c-block-list-maps-custom div.widget-zoom {
		bottom: initial;
	}

	.d-front-page-custom .c-block-list-maps-custom > div.relative {
		height: 335px !important;
		max-height: 335px !important;
	}

	.d-front-page-custom .c-block-list-maps-custom div.widget-map {
		@apply h-full !important;
		@apply max-h-full !important;
	}

	.c-block-list-maps-custom div.widget-zoom {
		bottom: 20px;
	}
}

@screen >=768 {
	.c-block-list-maps-custom div.widget-container,
	.c-block-list-maps-custom div.widget-map,
	.c-block-list-maps-custom div.widget-detached,
	.c-block-list-maps-custom div.widget-table {
		@apply h-full !important;
		@apply max-h-full !important;
	}

	.c-block-list-maps-custom div.widget-container {
		width: 65% !important;
	}

	.c-block-list-maps-custom div.widget-detached {
		width: 35% !important;
	}

	.d-front-page-custom .c-block-list-maps-custom div.widget-container {
		@apply w-full !important;
	}
}
/*! purgecss end ignore */
</style>
