<template>
	<div class="l-main mx-auto <768:overflow-x-hidden">
		<AppHeader />

		<!--
			Remove if cookieinformation is not picked as
			the cookie banner solution.
		-->
		<CookieInformation :tag="false" />

		<!--
			Go to ~/assets/js/theme-configuration.js to set
			the default solution configuration (typically
			the configuration of the main solution).
		-->
		<ThemeConfiguration />

		<main id="main" class="max-w-1440 mx-auto px-20 >=768:px-90">
			<Nuxt />
		</main>
		<AppFooter />
		<SpaOverlay @update="updateOverlayActiveness" />
	</div>
</template>

<script>
import ThemeConfiguration from '~/citi-baseline/components/ThemeConfiguration';
import CookieInformation from '~/citi-baseline/components/CookieInformation';
import AppHeader from '~/components/main/AppHeader';
import AppFooter from '~/components/main/AppFooter';
import SpaOverlay from '~/components/global/SpaOverlay';

export default {
	name: 'MainDefault',

	components: {
		ThemeConfiguration,
		CookieInformation,
		AppHeader,
		AppFooter,
		SpaOverlay,
	},

	data() {
		return {
			overlayIsActive: false,
		};
	},

	methods: {
		updateOverlayActiveness(active) {
			this.overlayIsActive = active;
		},
	},
};
</script>
