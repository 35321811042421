var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PortalTarget',{ref:"portal",class:[
		'spa-overlay z-11000',
		{
			'spa-overlay--deactivated': _vm.deactivate,
			'spa-overlay--active': _vm.active,
		},
		_vm.name === 'mobileMenu' ? 'spa-overlay--menu' : '',
	],attrs:{"name":_vm.name,"multiple":"","aria-hidden":_vm.active ? null : 'true'},on:{"change":_vm.updateOverlays}})
}
var staticRenderFns = []

export { render, staticRenderFns }