<!--
	Last modified: 2022/07/08 14:31:55
-->
<template>
	<Component :is="tag" v-if="tag">
		<slot></slot>
	</Component>
</template>

<script>
import Vue from 'vue';

const defaultValue = process.env.NODE_ENV !== 'production';
const cookieInformationData = Vue.observable({
	consentRef: null,

	categories: {
		unclassified: defaultValue,
		necessary: true,
		functional: defaultValue,
		statistic: defaultValue,
		marketing: defaultValue,
	},

	userHasInteracted: defaultValue,

	// Methods
	...['show', 'renew'].reduce((collection, method) => {
		collection[method] = (...args) => {
			if (cookieInformationData?.consentRef?.[method]) {
				cookieInformationData.consentRef[method](...args);
			} else {
				console.warn(`window.CookieConsent.${method}() not available.`);
			}
		};
		return collection;
	}, {}),

	async getUserHasInteracted() {
		return new Promise((resolve) => {
			if (
				!process.client ||
				cookieInformationData?.userHasInteracted ||
				process.env.NODE_ENV !== 'production'
			) {
				resolve(true);
				return;
			}
			const interval = window.setInterval(() => {
				if (cookieInformationData?.userHasInteracted) {
					window.clearInterval(interval);
					resolve(true);
				}
			}, 200);
		});
	},
});

export { cookieInformationData };
export default {
	name: 'CookieInformation',
	key: 'CookieInformation',

	provide: {
		cookieInformation: cookieInformationData,
	},

	props: {
		/**
		 * The tag can be set to false, in which
		 * case the component will not be rendered,
		 * including any children.
		 **/
		tag: {
			type: [String, Boolean],
			default: 'div',
			validate(value) {
				return value || typeof value === 'boolean';
			},
		},

		use: {
			type: Boolean,
			default: true,
		},
		googleConsentMode: {
			type: Boolean,
			default: false,
		},

		dataCulture: {
			type: String,
			default: 'DA',
		},

		scripts: {
			type: Array,
			default: () => [],
		},
	},

	head() {
		return {
			script: [
				this.use &&
					this.googleConsentMode && {
						hid: 'GoogleConsentMode',
						type: 'text/javascript',
						innerHTML: `
						window.dataLayer = window.dataLayer || [];
						function gtag() {
							dataLayer.push(arguments);
						}
						gtag('consent', 'default', {
							ad_storage: 'denied',
							analytics_storage: 'denied',
							wait_for_update: 500,
						});
						gtag('set', 'ads_data_redaction', true);
					`,
					},
				this.use && {
					hid: 'CookieInformation',
					id: 'CookieConsent',
					src: 'https://policy.app.cookieinformation.com/uc.js',
					'data-culture': this.dataCulture,
					type: 'text/javascript',
				},
				...this.scripts,
			].filter(Boolean),
			__dangerouslyDisableSanitizersByTagID: {
				GoogleConsentMode: ['innerHTML'],
			},
		};
	},

	computed: {
		CookieInformation() {
			return cookieInformationData;
		},
	},

	beforeMount() {
		this.updateCookieInformationConsentValues();
		window.addEventListener(
			'CookieInformationConsentGiven',
			this.updateCookieInformationConsentValues
		);
	},

	destroyed() {
		window.removeEventListener(
			'CookieInformationConsentGiven',
			this.updateCookieInformationConsentValues
		);
	},

	methods: {
		updateCookieInformationConsentValues() {
			if (process.client && window.CookieInformation) {
				// Set a direct reference to CookieInformation
				this.$set(
					cookieInformationData,
					'consentRef',
					window.CookieConsent
				);

				// Update categories
				Object.keys(cookieInformationData.categories)?.forEach?.(
					(category) => {
						const value =
							window.CookieInformation.getConsentGivenFor(
								`cookie_cat_${category}`
							);
						if (typeof value !== 'undefined') {
							this.$set(
								cookieInformationData.categories,
								category,
								value
							);

							// Update flag for user interaction
							if (
								cookieInformationData &&
								!cookieInformationData.userHasInteracted &&
								category !== 'necessary'
							) {
								this.$set(
									cookieInformationData,
									'userHasInteracted',
									true
								);
							}
						}
					}
				);
			}
		},
	},
};
</script>
